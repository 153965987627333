// const testRoutes = [
//   { path: "" },
//   { path: "my-profile" },
//   { path: "leafletmap" },
//   { path: "allFormComponent" },
//   { path: "InputField" },
//   { path: "editor" },
//   { path: "stepperForms" },
//   { path: "FormsWithValidation" },
//   { path: "progress" },
//   { path: "button" },
//   { path: "tab" },
//   { path: "autocomplete" },
//   { path: "checkbox" },
//   { path: "radiobox" },
//   { path: "selectbox" },
//   { path: "transfer" },
//   { path: "alert" },
//   { path: "modal" },
//   { path: "message" },
//   { path: "breadcrumb" },
//   { path: "dropdown" },
//   { path: "op_tag" },
//   { path: "op_timeline" },
//   { path: "uppy" },
//   { path: "dropzone" },
// ];

const options = [
  {
    key: "cms",
    label: "sidebar.cms",
    leftIcon: "ion-ios-cog",
    children: [
      {
        key: "menu",
        label: "sidebar.menu",
      },
      {
        key: "pages",
        label: "sidebar.pages",
      },
      {
        key: "settings",
        label: "sidebar.settings",
      },
      {
        key: "translates",
        label: "sidebar.translates",
      },
      {
        key: "languages",
        label: "sidebar.languages",
      },
    ],
  },
  {
    key: "users-list",
    label: "sidebar.users",
    leftIcon: "ion-ios-pint-outline",
    children: [
      {
        key: "users",
        label: "sidebar.users",
      },
      {
        key: "vendors",
        label: "sidebar.users_vendor",
      },
    ],
  },
  {
    key: "articles-list",
    label: "sidebar.articles",
    leftIcon: "ion-ios-pint-outline",
    children: [
      {
        key: "articles",
        label: "sidebar.articles",
      },
    ],
  },
  {
    key: "sections",
    label: "sidebar.sections",
    eventKey: "sidebar.sections",
    leftIcon: "ion-ios-list",
    children: [
      {
        key: "sections-slider",
        label: "sidebar.section_slider",
        eventKey: "sidebar.section_slider",
      },
      {
        key: "sections-brands",
        label: "sidebar.section_brands",
        eventKey: "sidebar.section_brands",
      },
      {
        key: "sections-advantages",
        label: "sidebar.section_advantages",
        eventKey: "sidebar.section_advantages",
      },
    ],
  },
  {
    key: "products",
    label: "sidebar.products",
    eventKey: "sidebar.products",
    leftIcon: "ion-ios-pint-outline",
  },
  {
    key: "categories",
    label: "sidebar.categories",
    eventKey: "sidebar.categories",
    leftIcon: "ion-ios-pint-outline",
  },
  {
    key: "reviews",
    label: "sidebar.reviews",
    eventKey: "sidebar.reviews",
    leftIcon: "ion-android-contacts",
  },
  {
    key: "feedbacks",
    label: "sidebar.feedback",
    eventKey: "sidebar.feedback",
    leftIcon: "ion-android-contacts",
  },
  {
    key: "variations",
    label: "sidebar.variations",
    eventKey: "sidebar.variations",
    leftIcon: "ion-android-contacts",
    children: [
      {
        key: "variations-values",
        label: "sidebar.variations_values",
      },
      {
        key: "variations-options",
        label: "sidebar.variations_options",
      },
    ],
  },
  {
    key: "specifications",
    label: "sidebar.specifications",
    eventKey: "sidebar.specifications",
    leftIcon: "ion-android-contacts",
    children: [
      {
        key: "specifications-categories",
        label: "sidebar.specifications_categories",
      },
      {
        key: "specifications-values",
        label: "sidebar.specifications_values",
      },
      {
        key: "specifications-options",
        label: "sidebar.specifications_options",
      },
    ],
  },

  {
    key: "orders",
    label: "sidebar.orders",
    eventKey: "sidebar.orders",
    leftIcon: "ion-cash",
  },
  {
    key: "locations",
    label: "sidebar.locations",
    eventKey: "sidebar.locations",
    leftIcon: "ion-ios-pint-outline",
  },
  {
    key: "brands",
    label: "sidebar.brands",
    eventKey: "sidebar.brands",
    leftIcon: "ion-ios-pint-outline",
  },

  // {
  //   key: "test",
  //   label: "sidebar.test",
  //   leftIcon: "ion-bag",
  //   children: testRoutes.map((q) => ({
  //     key: q.path,
  //     label: "sidebar." + q.path,
  //   })),
  // },
];
export default options;
